import _ from 'lodash';
import styled from 'styled-components';
// import { useRouter } from 'next/router';
import { useEffect } from 'react';
// import { useDesktop } from 'src/utils/helper';
// import AdsNative from './ads-native';
// ? TODO:  <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} page={KEY_ADS_PAGE.CONTENT} />
// import { SHOW_COMMEMORATE } from 'src/constants';
import { useMediaQuery } from 'react-responsive';
/* -------------------------------- KEY_TYPE -------------------------------- */
export const KEY_ADS_POSITION = {
  BILLBOARD: 'billboard',
  STICKY_1: 'sticky-1',
  STICKY_2: 'sticky-2',
  STICKY_3: 'sticky-3',
  INARTICLE_1: 'inarticle-1',
  INARTICLE_2: 'inarticle-2',
  INARTICLE_3: 'inarticle-3',
  INARTICLE_4: 'inarticle-4',
  POSTARTICLE: 'postarticle',
  AD1X1: 'ad1x1'
};

export const KEY_ADS_PAGE = {
  HOMEPAGE: 'homepage',
  CATEGORY: 'category',
  CONTENT: 'content',
  TAG: 'tag',
  SEARCH: 'search'
};

export const Ads = ({
  adsBox = false,
  slotId = '',
  slotSize = [],
  slotDefine = '',
  className = '',
  position,
  handler = () => {}
  // sizeMaps = ''
}) => {
  if (_.isEmpty(slotId) || _.isEmpty(slotDefine) || _.isEmpty(slotSize)) return null;
  // const isDesktop = useDesktop();
  // const slotSize = isDesktop ? sizeDesktop : !_.isEmpty(sizeMobile) && sizeMobile;

  useEffect(() => {
    window.googletag.cmd.push(() => {
      window.googletag.defineSlot(slotDefine, slotSize, slotId).addService(window.googletag.pubads()).setCollapseEmptyDiv(true, true);
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();

      window.googletag.cmd.push(function () {
        window.googletag.display(slotId);
      });
    });
  }, []);
  // console.log('position', position);
  // console.log('slotId', slotId);
  switch (position) {
    case 'light-box':
      return (
        <div>
          <AdsLightBox id='ads-nlightbox' className={`light-box-ads ${!adsBox ? 'close' : 'open'}`}>
            <div className={'light-box'} onClick={handler}>
              <AdsWrapper className={className}>
                <div id={slotId} />
              </AdsWrapper>
            </div>
          </AdsLightBox>
        </div>
      );

    default:
      return (
        <AdsWrapper className={className}>
          <div id={slotId} />
        </AdsWrapper>
      );
  }
};

const AdsLightBox = styled.div`
  z-index: 2147483647;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4142429c;

  &.close {
    display: none;
  }
  .light-box {
    position: relative;
    &::before {
      content: 'X';
      cursor: pointer;
      position: absolute;
      top: -24px;
      right: 0;
      color: rgba(0, 0, 0, 0.788);
      background-color: #fafafab7;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 2px 7px;
    }
  }
`;
const AdsWrapper = styled.div`
  &.ads-billboard {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto !important;
    min-height: 300px;
    iframe {
      margin: auto;
    }
    @media (max-width: 768px) {
      margin: 5px auto !important;
      min-height: 100px;
    }
  }
  &.ads-ad1x1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &.ads-sticky-1,
  &.ads-sticky-2,
  &.ads-sticky-3 {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  &.ads-postarticle {
    position: sticky;
    bottom: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  &.ads-inarticle-1,
  &.ads-inarticle-2,
  &.ads-inarticle-3,
  &.ads-inarticle-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 30px auto;
    overflow: hidden;
    img,
    iframe {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
`;

const DynamicAds = ({ adsBox = false, position = '', data = [], handler = () => {} }) => {
  const isMb = useMediaQuery({ query: '(max-width: 1023px)' });
  const isDt = useMediaQuery({ query: '(min-width: 1024px)' });
  const _adsArray = !_.isEmpty(data) ? data : [];
  // เช็คตำแหน่ง ADS
  const _adsSlot = _adsArray.find(_data => _data?.position === position);
  if (position === KEY_ADS_POSITION.POSTARTICLE) {
    // console.log(position, `ads-${position}`);
    return (
      <SlotWrapper className='ads-sticky-overflow'>
        <div className='mobile'>
          {!isDt && (
            <Ads adsBox={adsBox} handler={handler} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeMobile} position={position} />
          )}
        </div>
        <div className='desktop'>
          {!isMb && (
            <Ads adsBox={adsBox} handler={handler} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeDesktop} position={position} />
          )}
        </div>
      </SlotWrapper>
    );
  }
  if (!_.isEmpty(_adsSlot)) {
    return (
      <SlotWrapper>
        <div className='mobile'>
          {!isDt && (
            <Ads adsBox={adsBox} handler={handler} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeMobile} position={position} />
          )}
        </div>
        <div className='desktop'>
          {!isMb && (
            <Ads adsBox={adsBox} handler={handler} className={`ads-${position}`} slotDefine={_adsSlot?.slotDefine} slotId={_adsSlot?.slotId} slotSize={_adsSlot?.sizeDesktop} position={position} />
          )}
        </div>
      </SlotWrapper>
    );
  }
  return null;
};
export default DynamicAds;

const SlotWrapper = styled.div`
  .mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  .desktop {
    display: block;
    @media (max-width: 1025px) {
      display: none;
    }
  }
`;
