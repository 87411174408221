export const getScrollElement = (el) => {
  let newEl = el;
  let elTop = newEl.offsetTop - (newEl.offsetHeight * 3);
  while (newEl.offsetParent) {
    newEl = newEl.offsetParent;
    elTop += newEl.offsetTop;
  }
  return elTop;
};

export const isShouldFetch = () => {
  const footerEl = document.querySelector('.footer-wrapper');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop > scrollElement;
  }
  return false;
};

export const isShouldFetchReadmore = () => {
  const footerEl = document.querySelector('.fetch-remore');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop + 300 > scrollElement;
  }
  return false;
};
