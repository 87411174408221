import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';

import { useFetch } from 'services/useFetch';

import parse from 'html-react-parser';
import _ from 'lodash';
import { KEY_ADS_POSITION } from 'src/components/ads/dynamic-ads';
import { IS_NO_ROBOT_INDEX } from 'src/constants';
import {
  IMAGE_DEFAULT,
  WEB_DESC,
  WEB_KEYWORD,
  WEB_NAME,
  WEB_TITLE,
  WEB_URL
} from 'src/constants/layout';
import SectionMember from 'src/services/member-auth/components/section-member';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import { arrayEmpty, convertDatasetsLists, convertObjPath, dropSomeField, useDesktop } from 'src/utils/helper';
import { GET } from 'src/utils/services';
import useUser from 'src/utils/useUser';

const Layout = dynamic(import('src/components/layouts/index'));
const SectionLandingPage = dynamic(import('src/components/sections/section-landing-page'));
const SectionEvent = dynamic(import('src/components/sections/section-event'));
// const SectionCovid = dynamic(import('src/components/sections/section-event/section-covid'));
const SectionOne = dynamic(import('src/components/sections/sections-home/section-one'));
const SectionTwo = dynamic(import('src/components/sections/sections-home/section-two'), { ssr: false });
const SectionThree = dynamic(import('src/components/sections/sections-home/section-three'), { ssr: false });
const SectionFour = dynamic(import('src/components/sections/sections-home/section-four'), { ssr: false });
const SectionFive = dynamic(import('src/components/sections/sections-home/section-five'), { ssr: false });
const SectionElection2566 = dynamic(import('src/components/sections/section-election2566'));

const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'), { ssr: true });

const sectionsNeedShow = [
  'section-two',
  'section-three',
  'section-four',
  'section-five'
];

const PageIndex = ({
  articles,
  // dataCovid,
  coverData,
  dataLandingPage,
  dataAdsHeader,
  ads,
  dataTagWorldCup,
  dataSetWorldCup,
  dataElection2566
}) => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState(null);
  const [sectionMember, setSectionMember] = useState({});
  useEffect(() => {
    try {
      const checkUser = async () => {
        if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
          const response = await fetch('/api/auth/metadata', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            const res = await response.json();
            localStorage.setItem('login-meta-data', res);
            setSectionMember(decryptionClient(res));
          }
        }
      };
      const loginMetaData = localStorage.getItem('login-meta-data');
      const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
      const expireCondition = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;
      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        setSessionSevId(decryptionClient(data?.user));
        _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
      }
    } catch (error) {
      console.log('Error: Please enable website cookies. ' + error);
    }
  }, [data]);
  const nextSectionIndexRef = useRef(0);
  const sectionsVisibleRef = useRef([]);
  const [sectionsVisible, setSectionsVisible] = useState([]);

  const _article = {
    title: articles?.metaTag?.title || WEB_TITLE,
    keywords: articles?.metaTag?.keywords || WEB_KEYWORD,
    description: articles?.metaTag?.description || WEB_DESC
  };

  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      await loadMoreArticles();
    }
  };

  const loadMoreArticles = async () => {
    try {
      if (nextSectionIndexRef?.current < sectionsNeedShow.length) {
        if (isShouldFetch()) {
          const currentIndex = nextSectionIndexRef.current;
          const section = sectionsNeedShow[currentIndex];

          nextSectionIndexRef.current = currentIndex + 1;
          sectionsVisibleRef.current = [...sectionsVisibleRef.current, section];
          setSectionsVisible(sectionsVisibleRef.current);

          setTimeout(() => {
            loadMoreArticles();
          }, 1000);
        } else {
          document.addEventListener('scroll', handleScroll);
        }
      }
    } catch (err) {
      console.error(`==========> INDEX ERROR : ${nextSectionIndexRef.current}`, err);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo
        noindex={IS_NO_ROBOT_INDEX}
        nofollow={IS_NO_ROBOT_INDEX}
        title={_article.title}
        description={_article.description}
        openGraph={{
          type: 'article',
          locale: 'th_TH',
          url: WEB_URL.WEB,
          title: _article.title,
          description: _article.description,
          images: [
            {
              url: IMAGE_DEFAULT,
              width: '1920',
              height: '1080',
              alt: WEB_NAME
            }
          ]
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: _article.keywords
          }
        ]}
      />
      {/* --------------------------------- HEADER --------------------------------- */}
      <Head>
        <link rel='canonical' href={WEB_URL.WEB} />
        <meta property='twitter:title' content={_article?.title} />
        <meta property='twitter:description' content={_article?.description} />
        <meta property='twitter:image' content={IMAGE_DEFAULT} />

        {/* --------------------------------- Truehit -------------------------------- */}
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
                __th_page="home";
              `
          }}
        />
        {/* ---------------------------- SNIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "${WEB_NAME}",
                    "url": "${WEB_URL.WEB}",
                    "description": "${_article?.description}",
                    "sameAs": [
                      "${WEB_URL.FACEBOOK}",
                      "${WEB_URL.TWITTER}",
                      "${WEB_URL.YOUTUBE}",
                      "${WEB_URL.LINE}",
                      "${WEB_URL.INSTAGRAM}"
                    ],
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "${WEB_URL.WEB}search?q={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                }
              `
          }}
        />

        {/* ---------------------------- SPIPPET : WebSite --------------------------- */}
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `
                  {
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "name": "${WEB_NAME}",
                    "description": "${_article?.description}",
                    "publisher": {
                      "@type": "ProfilePage",
                      "name": "${WEB_NAME}"
                    }
                  }
                `
          }}
        />

        {/* -------------------------------- ADS : PPN ------------------------------- */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader)
          ? parse(`${dataAdsHeader?.header_mobile}`)
          : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader)
          ? parse(`${dataAdsHeader?.header_desktop}`)
          : ''}
        {/* <script
          async
          type='text/javascript'
          src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html:
              'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];',
          }}
        /> */}

      </Head>
      {/* ------------------------ First Screen Section ----------------------- */}
      <SectionLandingPage data={dataLandingPage} />

      {/* ------------------------ Cover Event on top only -------------------- */}
      {!arrayEmpty(coverData) && <SectionEvent data={coverData} />}
      <Layout ads={ads} sessionSevId={sessionSevId}>
        {/* ---------------------------- ADS : Billboard ----------------------------- */}
        <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />
        <SectionElection2566 dataEvent={dataElection2566} />
        <SectionOne
          data={articles}
          ads={ads}
          dataTagWorldCup={dataTagWorldCup}
          dataSetWorldCup={dataSetWorldCup}
        />
        {!_.isEmpty(sessionSevId) && !_.isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}
        {sectionsVisible.includes('section-two') && <SectionTwo ads={ads} />}
        {sectionsVisible.includes('section-three') && (
          <SectionThree ads={ads} />
        )}
        {sectionsVisible.includes('section-four') && <SectionFour ads={ads} />}
        {sectionsVisible.includes('section-five') && <SectionFive />}
        <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />
      </Layout>
    </>
  );
};

export async function getServerSideProps ({ req, res }) {
  const initProps = {
    statusCode: 200,
    errMessage: null,
    articles: null,
    dataCovid: null,
    coverData: [],
    dataLandingPage: null,
    dataAdsHeader: null,
    ads: [],
    dataTagWorldCup: [],
    dataSetWorldCup: null,
    dataElection2566: [],
    user: {}
  };

  try {
    const dataSet = await GET('/api/datasets');
    // World Cup
    if (!_.isEmpty(dataSet)) {
      // console.log('ddd', _.find(stat?.data?.data, { topic: 'section-world-cup' }));
      // WorldCup
      const data = _.find(dataSet, { topic: 'section-world-cup' })?.dataSet;
      if (!_.isEmpty(data)) {
        initProps.dataSetWorldCup = data;
        const tagName = convertDatasetsLists(data, 'tag-name', true);
        const resWC = await GET(
          `/api/search/tag${convertObjPath({ name: tagName })}`
        );
        if (!_.isEmpty(resWC)) {
          initProps.dataTagWorldCup = resWC;
        }
      }
      // election2566
      const dataElection2566 = _.find(dataSet, { topic: 'section-election2566' })?.dataSet;
      if (!_.isEmpty(dataElection2566)) {
        initProps.dataElection2566 = dataElection2566;
      }
    }
    const adsHeaders = await GET('/api/headerAds');
    if (!_.isEmpty(adsHeaders)) {
      initProps.dataAdsHeader = adsHeaders;
    }
    const payload = {
      block1: 6,
      block2: 6,
      block3: 8,
      lastestnews: 8
    };
    const uri = `/api/v1.0/home/section-one${convertObjPath(payload)}`;
    const { data } = await useFetch(uri);
    if (data) {
      initProps.articles = null;
    }
    initProps.articles = data;
    initProps.ads = data?.ads;
  } catch (err) {
    console.log('==========> INDEX ERROR', err.message);
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }

  // Landing Page
  const landinPage = await useFetch('/api/landing-page');
  if ('msg' in landinPage.data) initProps.dataLandingPage = [];
  initProps.dataLandingPage = landinPage.data;

  // "Cover images"
  const cover = await useFetch('/api/v1.0/imagecover');
  if ('msg' in cover?.data) initProps.coverData = [];
  initProps.coverData = cover.data;

  // "Covid 19"
  // const covid = await useFetch('/api/search/tag?name=%E0%B9%82%E0%B8%84%E0%B8%A7%E0%B8%B4%E0%B8%94-19');
  // if ('msg' in covid.data) initProps.dataCovid = null;
  // initProps.dataCovid = covid.data;

  return {
    props: initProps
  };
}

export default PageIndex;
