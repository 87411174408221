import _ from 'lodash';
import Cookies from 'js-cookie';
import sanitizeHtml from 'sanitize-html';
import { timestamp } from 'src/utils/helper';
import { decryptionClient, encryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { WEB_NAME } from 'src/constants/layout';

export async function sendSubscribe (user, selected, favoriteCategories, favoriteTags) {
  try {
    const ga = !('_ga' in user) ? [Cookies.get('_ga')] : typeof user._ga === 'string' ? [Cookies.get('_ga')] : [...user._ga, Cookies.get('_ga')];
    const gid = !('_gid' in user) ? [Cookies.get('_gid')] : typeof user._gid === 'string' ? [Cookies.get('_gid')] : [...user._gid, Cookies.get('_gid')];
    const nidEmpty = _.isEmpty(sanitizeHtml(Cookies.get('_nid') || ''));
    const nid =
      (!('_nid' in user) && !nidEmpty) || (typeof user._nid === 'string' && !nidEmpty)
        ? [sanitizeHtml(Cookies.get('_nid') || '')]
        : '_nid' in user && !nidEmpty
          ? [...user._nid, sanitizeHtml(Cookies.get('_nid') || '')]
          : '_nid' in user && nidEmpty
            ? user._nid
            : [];
    // console.log('sendSubscribe #1 :', user, selected);
    const favoriteCategoriesFiltered = favoriteCategories.filter(obj => obj.site !== WEB_NAME);
    const favoriteTagsFiltered = favoriteTags.filter(obj => obj.site !== WEB_NAME);
    const payload = {
      _ga: ga || [],
      _gid: gid || [],
      _nid: nid || [],
      _id: sanitizeHtml(user?._id || ''),
      email: sanitizeHtml(user?.email || ''),
      favoriteTags: [
        ...favoriteTagsFiltered,
        {
          site: WEB_NAME,
          tags: selected.tags
        }
      ],
      favoriteCategories: [
        ...favoriteCategoriesFiltered,
        {
          site: WEB_NAME,
          categories: selected.categories
        }
      ],
      updatedAt: new Date()
    };
    // console.log(payload);
    const response = await fetch('/api/member/subscribe', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(encryptionClient(payload))
    });
    const data = await response.json();
    const _data = decryptionClient(data?.data);
    // console.log('sendSubscribe #3 :', _data);

    if (!_.isEmpty(data?.data)) {
      localStorage.removeItem('login-meta-data');
      return { favoriteTags: _data?.user?.favoriteTags || [], favoriteCategories: _data?.user?.favoriteCategories };
    }
    return null;
  } catch (error) {
    if (error instanceof Error) console.error(`${timestamp()} ==========>  handleSubmit ERROR : `, error.message);
  }
}
